// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';

export class DevModel {
  static login(params: {
    eventId: string;
    eventSessionId: number;
  }): Promise<any> {
    return wsc.execute('/ws/dev/login', params) as any;
  }
}
// 2bd5207ab3567a071fb2bd8200b77b6a0d4b75923b3967641d28175e6d72fc6f
