// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { DownloadResponse } from '@ay-nestjs/share-client';
import { Observable } from 'rxjs';

export class ReportModel {
  static fetchEventList(
    param: {
      folderId: string | null;
      page: number;
    } = {
      folderId: null,
      page: 1,
    },
  ): Promise<{
    count: number;
    list: {
      eventId: string;
      eventName: string;
      platform: string;
      numberOfParticipants: number;
      uniqueNumberOfParticipants: number;
      usedModules: string[];
      folderId: string;
      folderName: string;
    }[];
  }> {
    return wsc.execute('/ws/report/fetchEventList', param) as any;
  }

  static search(keyword: string): Promise<
    (
      | {
          type: 'EVENT';
          eventId: string;
          eventName: string;
          folderId: string;
        }
      | {
          type: 'FOLDER';
          folderId: string;
          folderName: string;
        }
    )[]
  > {
    return wsc.execute('/ws/report/search', keyword) as any;
  }

  static fetchEvent(eventId: string): Promise<{
    eventId: string;
    eventName: string;
    platform: string;
    numberOfParticipants: number;
    uniqueNumberOfParticipants: number;
    sessions: {
      sessionId: string;
      sessionName: string;
      numberOfParticipants: number;
      numberOfGroupWinners: number;
      numberOfWinners: number;
      startedAt: Date;
      endedAt: Date;
    }[];
  }> {
    return wsc.execute('/ws/report/fetchEvent', eventId) as any;
  }

  static fetchSession(sessionId: string): Promise<{
    sessionId: string;
    sessionName: string;
    eventId: string;
    eventName: string;
    folderId: string;
    folderName: string;
    numberOfParticipants: number;
    numberOfWinners: number;
    startedAt: Date;
    endedAt: Date;
    participants: {
      name: string;
      email: string;
      loginPlatform: string;
      joinTime: Date;
      gameCompletion: number;
      leaveIndex: number;
      leaveTime: Date;
    }[];
    winners: {
      winnerId: number;
      received: boolean;
      award: string;
      name: string;
      email: string;
      moduleUsed: string;
      winningTime: Date;
    }[];
  }> {
    return wsc.execute('/ws/report/fetchSession', sessionId) as any;
  }

  static downloadEvent(eventId: string): Observable<DownloadResponse> {
    return wsc.download('/ws/report/downloadEvent', eventId) as any;
  }

  static downloadSession(sessionId: string): Observable<DownloadResponse> {
    return wsc.download('/ws/report/downloadSession', sessionId) as any;
  }

  static receive(winnerId: number, received: boolean): Promise<void> {
    return wsc.execute('/ws/report/receive', winnerId, received) as any;
  }
}
// 52ec2cc74aacc6888e750549d855715ca39ded32df60b85b92ca9b5a36816b9d
