// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { ParticipantDto } from '../_module/dto/participant.dto';
import { wsc } from '../wsc';
import { Observable } from 'rxjs';

export class CountMarioModel {
  static listenAnswer(): Observable<{
    participant: ParticipantDto;
    selected: number[];
  }> {
    return wsc.subscribe('/ws/count-mario/listenAnswer') as any;
  }

  static fetchAnswers(): Promise<
    { participant: ParticipantDto; selected: number[] }[]
  > {
    return wsc.execute('/ws/count-mario/fetchAnswers') as any;
  }

  static fetchCorrectlyCount(): Promise<any> {
    return wsc.execute('/ws/count-mario/fetchCorrectlyCount') as any;
  }

  static fetchCorrectlyParticipants(): Promise<ParticipantDto[]> {
    return wsc.execute('/ws/count-mario/fetchCorrectlyParticipants') as any;
  }

  static isCountExecuted(): Observable<boolean> {
    return wsc.subscribe('/ws/count-mario/isCountExecuted') as any;
  }

  static listenCountRecord(): Observable<{
    identifier: string;
    count: number;
  }> {
    return wsc.subscribe('/ws/count-mario/listenCountRecord') as any;
  }

  static setWinner(identifiers: string[]): Promise<any> {
    return wsc.execute('/ws/count-mario/setWinner', identifiers) as any;
  }

  static fetchWinner(): Promise<ParticipantDto[]> {
    return wsc.execute('/ws/count-mario/fetchWinner') as any;
  }

  static count(count: number): Promise<void> {
    return wsc.execute('/ws/count-mario/count', count) as any;
  }

  static answer(selected: number[]): Promise<any> {
    return wsc.execute('/ws/count-mario/answer', selected) as any;
  }

  static isAnswered(): Promise<any> {
    return wsc.execute('/ws/count-mario/isAnswered') as any;
  }

  static fetchAnswered(): Promise<any> {
    return wsc.execute('/ws/count-mario/fetchAnswered') as any;
  }

  static isCorrectly(): Promise<boolean> {
    return wsc.execute('/ws/count-mario/isCorrectly') as any;
  }

  static isWinner(): Promise<boolean> {
    return wsc.execute('/ws/count-mario/isWinner') as any;
  }

  static fetchCount(): Promise<number | null> {
    return wsc.execute('/ws/count-mario/fetchCount') as any;
  }
}
// 9fdd7da03765091f7a56a6d52f73428176d8ecffe44ff371f7a40734ca38ff7b
